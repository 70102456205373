$yellow: #E5BE1A;

.pageWrapper{
  margin-left: 80px;
  margin-top: 50px;
}
.blockWrapper {
  //margin-left: 180px;
  margin-left: 5px;
}
.title {
  color: $yellow;
  font-size: 16px;
  font-weight: bold;
}

.image{
  width: 333px;
  margin-top: 15px;
  margin-left: 5px;
  height: 220px;
  object-fit: cover;
}

.divider {
  height: 1px;
  width: 75%;
  background-color: #454751;
  margin-top: 30px;
  margin-bottom: 5px;
}

.subTitle {
  color: $yellow;
  margin-left: 5px;
}

.blockWrapper {
  //margin-left: 180px;
}

.inputWrapper {
  margin-right: 15px;
}
.titleName {
  color: #7F8991;
  font-size: 13px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.buttonWrapper {
  width: 200px;
  margin-right: 35px;
}

.buttonsWrapper {
  display: flex;
  margin-top: 50px;
  margin-bottom: 250px;
}