$header-height: 80px;
.blockWrapper {
  display: flex;
  background-color: #292B30;
  color: #ffffff;
  width: 100%;
}

.contentWrapper {
  width: 100%;
  float:left;
  overflow-y: auto;
  height: calc(100vh - #{$header-height});
}