$yellow: #E5BE1A;

.pageWrapper{
  margin-left: 80px;
  margin-top: 50px;
}
.blockWrapper {
  margin-left: 180px;
}
.title {
  color: $yellow;
  font-size: 16px;
  font-weight: bold;
}