.wrapper {
  margin-top: 18px;
  margin-bottom: 50px;
}
.title {
  color: #7F8991;
  font-size: 13px;
  margin-bottom: 5px;
  margin-left: 25px;
}
.secondTitle {
  margin-left: 325px;
  color: #7F8991;
  font-size: 13px;
  margin-bottom: 5px;

}
.input {
  width: 320px;
  height: 30px;
  padding-left: 8px;
}

.secondInput {
  width: 320px;
  height: 30px;
  padding-left: 8px;
  margin-left: 25px;
}

.buttonWrapper {
  width: 32px;
  margin-left: 10px;
}

.inputWrapper {
  display: flex;
  //align-items: center;
  margin-bottom: 10px;
}

.index {
  color: #7F8991;
  font-size: 13px;
  width: 25px;
}

.titleWrapper {
  display: flex;
}
.container {
  color: black;
  margin-left: 10px;
  background-color: white;
  border-radius: 3px;
}
.suggestion {
  margin-left: -25px;
  list-style-type: none;
  margin-top: 5px;
  cursor: pointer;
}