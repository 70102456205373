$yellow: #E5BE1A;

.pageWrapper{
  margin-left: 80px;
  margin-top: 50px;
}
.blockWrapper {
  margin-left: 180px;
}
.blockWrapperMinting {
  margin-left: 273px;
}
.title {
  color: $yellow;
  font-size: 16px;
  font-weight: bold;
}

.subTitle {
  color: $yellow;
  margin-left: 5px;
  margin-right: 95px;
}
.subTitleTime {
  color: $yellow;
  margin-right: 187px;
}
.choosePathStyle{
  margin-left: 17rem;
}

.divider {
  height: 1px;
  width: 75%;
  background-color: #454751;
  margin-top: 30px;
  margin-bottom: 5px;
}

.rowInputs {
  display: flex;
}

.inputWrapper {
  margin-right: 15px;
}

.greyText {
  color: #7F8991;
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 13px;
}

.grey {
  color: #7F8991;
  font-size: 13px;
  margin-top: 18px;
  width: 175px;
}
.greyForArray {
  color: #7F8991;
  font-size: 13px;
  margin-top: 18px;
  width: 150px;
}
.greyForArrayWords {
  margin-left:50px ;
  color: #7F8991;
  font-size: 13px;
  margin-top: 18px;
  width: 180px;
}

.wordsExplanations {
  margin: 18px 30px 50px 50px;
  color: #7F8991;
  font-size: 13px;
  width: 20%;
  word-wrap: break-word;
}
.sizeTime {
  display: flex;
  justify-items: center;
  flex-wrap:wrap;
}
.styleTo{
  margin-left: 15px;
}

.distanceBetweenBlocks{
  width: 100px;
}
@media (max-width: 1450px) {
  .styleTo{
    margin-left: 0px;
  }
  .subTitleTime {
    color: $yellow;
    margin-right: 60px;
  }
  .subTitle {
    color: $yellow;
    margin-left: 5px;
    margin-right: 40px;
  }
  .subTitleTime {
    color: $yellow;
    margin-right: 137px;
  }
  .blockWrapperMinting {
    margin-left: 218px;
  }
}

.fileCounter {
  min-height: 32px;
  max-height: 100px;
  overflow-y: auto;
  width: 323px;
  border: solid 1px #E1E1E0;
  display: flex;
  align-items: center;
  padding: 5px;
  color: #E1E1E0;
  border-radius: 4px;
}
.fileCounterError {
  min-height: 32px;
  width: 458px;
  border: solid 1px #E1E1E0;
  display: flex;
  align-items: center;
  padding: 5px;
  color: #E1E1E0;
  border-radius: 4px;
  background-color: red;
}

.generalGreyColumn {
  display: flex;
  margin-left: 5px;
  margin-bottom: 80px;
}

.buttonWrapper {
  width: 200px;
  margin-right: 35px;
}

.buttonsWrapper {
  display: flex;
  margin-top: 50px;
  margin-bottom: 250px;
}

table {
  color: #E1E1E0;
}