$yellow: #E5BE1A;

.pageWrapper{
  margin-left: 80px;
  margin-top: 50px;
}
.blockWrapper {
  margin-left: 180px;
}
.title {
  color: $yellow;
  font-size: 16px;
  font-weight: bold;
}
.headerBlock {
  display: flex;
  justify-content: space-between;
  padding: 50px 35px 50px 80px;
}
.dFlex {
  display: flex;
}
.searchIcon {
  width: 16px;
}

.searchContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgrey;
  border-radius: 5px;
  margin-left: 10px;
}

.searchButton {
  background-color: $yellow;
  height: 100%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}

.searchInput {
  background-color: lightgrey;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  height: 85%;
  width: 260px;
  padding-left: 5px;
  border: lightgrey;
}
.clearButton{
  cursor: pointer;
  padding-right: 10px;
  padding-top: 2px;
}

.center {
  display: flex;
  justify-content: center;
  width: 80%;
}

.activeRound {
  height: 12px;
  width: 12px;
  background-color: $yellow;
  display: inline-block;
  border-radius: 6px;
}

.inActiveRound {
  height: 12px;
  width: 12px;
  background-color: #ffffff;
  display: inline-block;
  border-radius: 6px;
}

.borderSpacing {
  border-spacing: 0;
  width: 100%;
}

.tableHeader {
  width: 100%;
  background-color: #7f7f7f;
  color: black;
  font-size: 12px;
  font-weight: lighter;
}
.tableColumnWrapper {
  text-align: left;
  margin-top: 10px;
  font-weight: 400;
}

.arrowBottom {
  width: 12px;
  transform: rotate(90deg);
  position: relative;
  top: 2px;
}

.arrowTop {
  width: 12px;
  transform: rotate(-90deg);
  position: initial;
  top: 2px;
}

.emptyArrow {
  display: inline-block;
  width: 12px;
  height: 12px;
}

th,
td {
  padding-left: 10px;
}

.tableRow td {
  padding-top: 5px;
  padding-bottom: 5px;
}
.tableRow:nth-child(odd) td {
  background-color: #3b3d46;
}
.borderSpacingDiv {
  border-spacing: 0;
  width: 100%;
  overflow-y: auto;
  height: 79vh;
}
.tableRows {
  height: 72vh;
  overflow-x: hidden;
}