$header-height: 80px;
$yellow: #E5BE1A;

.header {
  height: $header-height;
  background-color: #2E313A;
  display: flex;
  justify-content: space-between;
  padding-left: 25px;
}

.title {
  font-size: 16px;
  color: #E1E1E0;
  white-space: nowrap;
}

.description {
  font-size: 16px;
  font-weight: bold;
  color: $yellow;
  white-space: nowrap;
  cursor: default;
}

.logo {
  max-width: 240px;
}

.headerBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  min-width: 75px;
  margin-left: 3%;
  cursor: default;
}

.headerBlockWrapper {
  display: flex;
  width: 100%;
  margin-left: 4%;
}

.userBlock {
  display: flex;
  align-items: center;
  padding-right: 14%;

}

.user {
  max-width: 30px;
}
.newBlock{
  margin-left:40% ;
}