.input {
  width: 324px;
  height: 30px;
  padding-left: 8px;
  background-color: #C52F2F;
  border: 0;
  border-radius: 2px;

}



