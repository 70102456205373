$red: #C52D2F;
$yellow: #E5BE1A;
.textColorYellow{
  color: $yellow;

}
.textColorWhite{
  color: white;
  text-align: center;
}
.buttonStyle{
  margin-top: 5%;
  display: flex;
  justify-content: space-around;
}

