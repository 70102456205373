.wrapper {
  margin-top: 38px;
  cursor: pointer;
}
.title {
  color: #7F8991;
  font-size: 13px;
  margin-bottom: 5px;
}

.value {
  font-size: 13px;
  margin-left: 15px;
}

.checkboxWrapper {
  display: flex;
  margin-bottom: 15px;
  margin-top: 25px;
}