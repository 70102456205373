$yellow: #E5BE1A;
.blockWrapper {
  background-color: #292B30;
  color: #ffffff;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoWrapper {
  background-color: #2E313A;
  width: 680px;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonWrapper {
  background-color: #3B3D46;
  width: 680px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.logo {
  width: 400px;
}
.connectButton {
  background-color: $yellow;
  width: 280px;
  height: 60px;
  font-size: 24px;
  padding: 20px;
  border-radius: 5px;
  font-weight: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #292B30;
}
.error {
  font-size: 23px;
  color: #E91814;
}