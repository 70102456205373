.wrapper {
  margin-top: 18px;
  width: 334px;
}
.title {
  color: #7F8991;
  font-size: 13px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

}

.input {
  width: 320px;
  height: 30px;
  padding-left: 8px;
}
.inputError {
  border: 0;
  border-radius: 2px;
  width: 324px;
  height: 34px;
  padding-left: 8px;
  background-color: #C52F2F;
}
.bulletPoint{
  font-size: 7px;
  margin-left: 3px;
}