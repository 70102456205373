$red: #C52D2F;
$yellow: #E5BE1A;
.danger {
  background-color: $red;
}
.primary {
  background-color: $yellow;
}


.small {
  height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
}

.medium {
  height: 34px;
  padding-left: 11px;
  padding-right: 10px;
  border-radius: 3px;
  font-weight: bold;
}

.big {

}

.button {
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #292B30;

}

