.wrapper {
  margin-top: 38px;
  cursor: pointer;
  width: 500px;
}
.optionsWrapper {
  display: flex;
  flex-wrap: wrap;
}
.title {
  color: #7F8991;
  font-size: 13px;
  margin-bottom: 5px;
}

.value {
  font-size: 13px;
  margin-left: 15px;
}

.checkboxWrapper {
  display: flex;
  margin-bottom: 15px;
  margin-top: 25px;
  width: 250px;
}
.input{
  width: 320px;
  height: 30px;
  padding-left: 8px;
}