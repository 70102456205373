$yellow: #E5BE1A;
$header-height: 80px;

.fixedHeader {
  position: fixed;
  z-index: 99;
  top: $header-height;
  width: 90%;
  background-color: #292B30;
  //background-color: #ff7b00;
  height: 250px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nftImage{
  height: 220px;
  object-fit: cover;
}

.arrowsWrapper {
  display: flex;
  align-items: center;
}

.counter {
  color: $yellow;
  font-size: 16px;
  margin: 40px;
}
.arrow {
  cursor: pointer;
}
.disabledInfo {
  height: 32px;
  width: 280px;
  border: solid 1px #E1E1E0;
  display: flex;
  align-items: center;
  padding-left: 5px;
  color: #7F8991;
  border-radius: 4px;
}

.headerInfoWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 400px;
}



.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageWrapper{
  margin-left: 80px;
  margin-top: 300px;
}
.blockWrapper {
  margin-left: 180px;
}
.title {
  color: $yellow;
  font-size: 16px;
  font-weight: bold;
}

.subTitle {
  color: $yellow;
  margin-left: 5px;
}
.headerInfo{
  display: flex;
  align-items: center;
  justify-content:space-between;
  margin-bottom: 15px;
  width: 400px;
}

.time {
  color: #fff;;
}

.divider {
  height: 1px;
  width: 75%;
  background-color: #454751;
  margin-top: 30px;
  margin-bottom: 5px;
}

.rowInputs {
  display: flex;
}

.inputWrapper {
  margin-right: 15px;
}
.inputQuotes {
  margin-right: 15%;
  height: 50px;
}

.greyText {
  color: #7F8991;
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 35px;
}

.grey {
  color: #7F8991;
  font-size: 13px;
  margin-top: 18px;
  width: 175px;
}
.greyForArray {
  color: #7F8991;
  font-size: 13px;
  margin-top: 18px;
  width: 150px;
}
.greyForArrayQuotes {
  color: #7F8991;
  font-size: 13px;
  margin-top: 18px;
  width: 150px;
  margin-right: 25px;
}

.fileCounter {
  height: 32px;
  width: 458px;
  border: solid 1px #E1E1E0;
  display: flex;
  align-items: center;
  padding-left: 5px;
  color: #E1E1E0;
  border-radius: 4px;
}

.generalGreyColumn {
  display: flex;
  margin-left: 5px;
  margin-bottom: 80px;
}

.buttonWrapper {
  width: 200px;
  margin-right: 35px;
  margin-top: 20px;
}

.buttonsWrapper {
  padding-right: 60px;
}

table {
  color: #E1E1E0;
}