$header-height: 80px;
$yellow: #E5BE1A;
.sideMenu {
  height: calc(100vh - #{$header-height});
  background-color: #2B2D35;
}
.contentWrapper {
  display: flex;
}

.menuIcon {
  text-align: right;
  padding: 10px;
}

.whiteItem {
  color: #FFFFFF;
  font-weight: bold;
  padding: 3px 5px 3px 20px;
  margin-bottom: 2px;
  margin-top: 20px;
}

.subItem {
  color: #60676e;
  padding: 3px 5px 3px 40px;
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: pointer;
}

.activeItem {
  background-color: $yellow;
  color: #25282f;
}